#top {
  /* background-image: linear-gradient(135deg, #086199 10%, #595812 100%); */
  background-image: var(--home-masthead-filter), url('/images/home/masthead.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-blend-mode: darken;
  font-family: 'Zilla Slab', serif;
}

#masthead {
  /* padding: 0; */
  background: transparent;
}

.countdown {
  display: flex;
  flex-direction: column;
  float: right;
  text-align: center;
}

.countdown-box {
  width: 100px;
  margin: 10px 0;
  float: right;
}

.countdown-number {
  font-weight: 900;
  font-size: 3em;
  background-color: var(--home-countdown-number);
  color: var(--home-countdown-number-text);
  mix-blend-mode: var(--home-countdown-number-blend-mode);
}

.countdown-unit {
  font-weight: 900;
  font-size: 1.2em;
  background-color: var(--home-countdown-unit);
  color: var(--home-countdown-unit-text);
  mix-blend-mode: var(--home-countdown-unit-blend-mode);
}

.competetion {
  margin-top: 150px;
  text-align: center;
}

.competetion span {
  display: block;
  line-height: normal;
}

.competetion-name {
  font-weight: 900;
  font-size: 2.5em;
  color: var(--home-competetion-name);
}

.competetion-date {
  font-weight: bold;
  font-size: 2em;
  color: var(--home-competetion-date);
}

.competetion-place {
  font-size: 1em;
  color: var(--home-competetion-place);
}

.btn-join {
  margin-right: 10px;
  background-color: #212529 !important;
  border: 0px solid transparent !important;
  border-radius: 0 !important;
  border-bottom: 1px solid transparent !important;
}

.btn-join:hover {
  background-color: #212529d5 !important;
}

.btn-join:focus {
  border-bottom: 1px solid white !important;
}

.btn-getinvite {
  margin-right: 10px;
  background-color: #282b2e !important;
  border: 0px solid transparent !important;
  border-radius: 0 !important;
  border-bottom: 1px solid transparent !important;
}

.btn-getinvite:hover {
  background-color: #282b2ed5 !important;
}

.btn-getinvite:focus {
  border-bottom: 1px solid white !important;
}

@media only screen and (max-width: 1140px) {
  .competetion {
    margin-bottom: 150px;
  }

  .countdown {
    flex-direction: row;
    justify-content: center;
    float: none;
  }

  .countdown-box {
    margin: 0 10px;
  }
}