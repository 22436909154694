#about-categories {
  margin-bottom: 0 !important;
}

#about-top {
  background-image: var(--about-masthead-filter), url('/images/about/masthead.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-blend-mode: darken;
  height: 400px;
  text-align: center;
}

#about-top span {
  font-family: 'Zilla Slab', sans-serif;
  font-weight: 900;
  font-size: 3em;
  color: var(--about-masthead-text);
  line-height: 400px;
  background-color: var(--about-masthead-text-background);
  padding: 5px 15px;
}
