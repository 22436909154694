#contacts {
  padding-left: -15px;
}

.contacts-col {
  padding: 0;
}

.people {
  border: 0;
  border-radius:0px !important;
  margin: 10px;
  padding-bottom: 20px;
  height: calc(100% - 20px);
}

.people .card-img-top {
  border-radius:0px !important;
  text-align: center;
  max-height: 346.667px;
  max-width: 346.667px;
}

.contacts-col:nth-child(odd) * {
  color: var(--contact-people-odd);
  background-color: var(--contact-people-odd-background);
}

.contacts-col:nth-child(even) * {
  color: var(--contact-people-even);
  background-color: var(--contact-people-even-background);
}

.people-icon {
  padding-right: 5px;
}