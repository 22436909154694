/*
  Carbon Theme: Black and White

  Author: Gökberk Akdeniz
*/

/* ############### VARIABLES ############## */

:root {
  --accent1: #EB5040;
  --accent2: #D11500;
  --accent3: #9E1000;
  --accent4: #573531;
  --accent5: #520800;
  --accent6: #100E17;
  --accent7: #212529;

  /* ====================================== */
  /* ============= BOOTSTRAP ============== */
  /* ====================================== */

  /* ------------- List Group ------------- */
  --ordered-list: #b2b9b9;
  --ordered-list-header: #363636;
  --ordered-list-header-text: #ffffff;
  
  /* ------------- Accordion ------------- */
  --accordion: #CDD6D6;
  --accordion-text: #1f1919;
  --accordion-header: #15170E;
  --accordion-header-text: #ececec;
  --accordion-accent: #C43B4E;

  /* ====================================== */
  /* ============= COMPONENTS ============= */
  /* ====================================== */

  /* --------------- HEADER --------------- */
  --navbar: #000000;
  --navbar-brand: #FFFFFF;
  --navbar-link: #808080;
  --navbar-link-active: #FFFFFF;
  --navbar-link-hover: #000000;
  --navbar-link-hover-background: #FFFFFF;

  /* --------------- FOOTER --------------- */
  --footer: #000000;
  --footer-student-club-name: #FFFFFF;
  --footer-student-club-address: #CCCCCC;
  --footer-text: #FFFFFF;
  --footer-link: #999999;
  --footer-coded: #4682B4;
  --footer-love: #E25555;

  /* --------------- SECTION --------------- */
  --section-title: #cdd6d6;
  --section-title-background: #100E17;
  --section-accent: #A7001D;

  /* ====================================== */
  /* ================ PAGES =============== */
  /* ====================================== */
  
  /* ---------------- ABOUT --------------- */
  --about-masthead-text: #dce6e6;
  --about-masthead-text-background: #100E17;
  --about-masthead-filter: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  
  /* --------------- ARCHIVE -------------- */

  /* ------------- CATEGORIES ------------- */
  --category-left: #1f1919;
  --category-left-text: #ffffff;
  --category-right: #cdd6d6;
  --category-right-text: #1f1919;

  /* --------------- CONTACT -------------- */
  --contact-people-odd: #100E17;
  --contact-people-odd-background: #cdd6d6;
  --contact-people-even: #100E17;
  --contact-people-even-background: #cdd6d6;

  /* ---------------- HOME ---------------- */
  --home-masthead-filter: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  --home-competetion-name: #FFFFFF;
  --home-competetion-place: #FFFFFF;
  --home-competetion-date: #FFFFFF;
  --home-countdown-number: #000000;
  --home-countdown-number-text: #FFFFFF;
  --home-countdown-number-blend-mode: overlay;
  --home-countdown-unit: #ffffff;
  --home-countdown-unit-text: #000000;
  --home-countdown-unit-blend-mode: screen;

  /* ------------- INVITATION ------------- */

  /* ------------- NOT FOUND -------------- */
  --not-found-text: slategrey;

  /* ------------ REGISTRATION ------------ */

  /* -------------- SPONSORS -------------- */

  /* -------------- SURVIVING ------------- */

  /* ---------------- TEAM ---------------- */

}

/* ################# CUSTOM ############### */

body {
  /* background-color: #ffffff; */
  background-color: #CDDCDC;
  background-image: radial-gradient(at 50% 100%, rgba(255,255,255,0.50) 0%, rgba(0,0,0,0.50) 100%), linear-gradient(to bottom, rgba(255,255,255,0.25) 0%, rgba(0,0,0,0.25) 100%);
  background-blend-mode: screen, overlay;
}