.section-title {
	text-align: center;
	padding-top: 15px !important;
	font-family: 'Zilla Slab', serif;
}

.section-title div {
	background-color: var(--section-title-background);
	border-left: 5px solid var(--section-accent);
	padding: 5px 0;
}

.section-title div span {
	padding: 5px;
	color: var(--section-title);
	font-size: 1.75rem;
}

.section-content {
	padding: 15px 5px 15px 5px;
}

.section:last-of-type {
	margin-bottom: 100px;
}