.category {
  background-color: var(--category-left);
  margin-bottom: 15px;
}

.category-left {
  padding: 25px;
  text-align: center;
}

.category-short-text {
  min-height: 103px;
}

.category-name {
  color: var(--category-left-text);
  font-family: 'Zilla Slab', serif;
}

.category-right {
  padding: 25px;
  background-color: var(--category-right);
  color: var(--category-right-text);
}