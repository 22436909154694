.navbar-default {
    background-color: var(--navbar) !important;
    position: sticky;
    padding: 0 16px;
}

#navbar-menu .navbar-nav {
    min-height: 45px;
}

.nav-link {
    font-size: 14px;
    line-height: 45px;
    font-weight: 700;
    color: var(--navbar-link);
}

.nav-link.active:not(#logo) {
    color: var(--navbar-link-active);
}

.nav-link:not(#logo):hover {
    color: var(--navbar-link-hover);
    background-color: var(--navbar-link-hover-background);
}

.navbar-brand {
    font-weight: 700;
    padding: 0;
}

.navbar-toggler {
    border: 0;
}

#logo {
    color: var(--navbar-brand);
}